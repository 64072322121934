@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100..900&display=swap');

* {
  box-sizing: border-box;
  margin : 0;
  padding : 0;

  font-size : 1rem;
  font-family: "Noto Sans KR", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}

body {
  margin : 0;
}